body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body, #root, .App {
  height: 100%;
} 

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.Titan {
  font-family: 'Titan One', cursive;
}
.Orange {
  color: yellow;
}
.Red {
  color: red;
}
.Logo {
  height:30px;
  width:32px;
}

.App-header {
  /* background-color: #048f04; */
  height: 5rem;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center; 
  font-size: calc(10px + 2vmin);
  color: white;
  box-shadow: 0 0 5px rgba(115, 115, 115, .8),0 2px 6px rgba(115,115,115,.7);
  background-image: url(/static/media/grass-header.62469a1b.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.App-grass {
  background-image: url(/static/media/grass-header.62469a1b.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

body, html {
  height: 100%;
}

.bg { 
  /* The image used */
  background-image: url(/static/media/background.923d42aa.jpg);
  /* Full height */
  height: 100%; 

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.dimmer {
  opacity: 0.5;
  filter: alpha(opacity=50); /* For IE8 and earlier */
}

.panel {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
}

#customers {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  font-size: 75%;
  border-collapse: collapse;
  width: 100%;
  background: white;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: orange;
  color: black;
}

.message {
  background-color: white;
  color: black;
}
.frame {
  width: 800px;
  height: 600px;
}

